<template>
  <v-card class="mx-auto" :disabled="loading">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <span
            class="text-h6 ml-1"
            v-text="
              `${$route.meta.title} ${
                loading
                  ? ''
                  : table_data.length > 0
                  ? `(${table_data.length})`
                  : ''
              }`
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                color="grey"
                dark
                class="ml-1"
                @click.prevent="downloadZip"
              >
                <v-icon> mdi-download </v-icon>
              </v-btn>
            </template>
            <span v-text="`Descargar documentación de casos`" />
          </v-tooltip>
          <v-tooltip v-if="login.role_id == 1 && table_data.length > 0" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                color="success"
                class="ml-1"
                @click="createXlsx"
              >
                <v-icon> mdi-table-arrow-down </v-icon>
              </v-btn>
            </template>
            <span v-text="'Descargar XLSX'" />
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                dark
                x-small
                class="ml-1"
                color="orange darken-3"
                @click="paymentsDialog"
              >
                <v-icon> mdi-account-cash </v-icon>
              </v-btn>
            </template>
            <span v-text="'Generar orden(es) de pago'" />
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                dark
                x-small
                class="ml-1"
                color="teal darken-3"
                :to="{
                  name: 'facturacion_servicios.ordenes_pago',
                }"
              >
                <v-icon> mdi-cash-sync </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ordenes de pago'" />
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-badge
                overlap
                :color="
                  (doctors_comments_pending > 0 ? 'deep-orange' : 'cyan') +
                  ' darken-1'
                "
                :content="doctors_comments_pending"
                :dot="doctors_comments_pending == 0"
              >
                <v-btn
                  v-on="on"
                  fab
                  dark
                  x-small
                  class="ml-1"
                  :to="{
                    name: 'facturacion_servicios.doctors.comments',
                  }"
                  :color="
                    (doctors_comments_pending > 0 ? 'deep-orange' : 'cyan') +
                    ' darken-1'
                  "
                  :loading="doctors_comments_pending_ldg"
                >
                  <v-icon> mdi-comment-question </v-icon>
                </v-btn>
              </v-badge>
            </template>
            <span v-text="'Fact. Servicios | Méd. Preguntas o comentarios'" />
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-badge
                overlap
                :color="
                  (bill_comments_pending > 0 ? 'deep-orange' : 'cyan') +
                  ' darken-4'
                "
                :content="bill_comments_pending"
                :dot="bill_comments_pending == 0"
              >
                <v-btn
                  v-on="on"
                  fab
                  dark
                  x-small
                  class="ml-1"
                  :to="{
                    name: 'rs_service_bills.bill_comments',
                  }"
                  :color="
                    (bill_comments_pending > 0 ? 'deep-orange' : 'cyan') +
                    ' darken-4'
                  "
                  :loading="bill_comments_pending_ldg"
                >
                  <v-icon> mdi-comment-check </v-icon>
                </v-btn>
              </v-badge>
            </template>
            <span v-text="'Fact. | Comentarios aseguradoras'" />
          </v-tooltip>
        </v-col>
        <v-col cols="12" xs="12" md="2">
          <v-autocomplete
            label="Tipo"
            v-model="rs_service_type_id"
            :items="filter_rs_service_types"
            item-value="id"
            :item-text="(v) => v.type"
            :loading="filter_rs_service_types_loading"
            dense
            :disabled="loading"
            @change="getTableData"
          />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <v-select
            label="Selecciona una opción..."
            v-model="filter"
            :items="filters"
            item-value="id"
            :item-text="(v) => v.value"
            dense
            :disabled="loading"
            @change="getTableData"
          />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <v-autocomplete
            label="Aseguradora(s)"
            v-model="filter_insurance"
            :items="filter_insurances"
            item-value="id"
            :item-text="(v) => v.name"
            :loading="filter_insurances_loading"
            dense
            multiple
            :disabled="loading || !filter"
            @change="getTableData"
          />
        </v-col>
        <v-col cols="12" xs="12" md="2">
          <v-autocomplete
            label="Servicio"
            v-model="rs_type_id"
            :items="filter_rs_types"
            item-value="id"
            :item-text="(v) => v.type"
            :loading="filter_rs_types_loading"
            dense
            :disabled="loading || !filter"
            @change="getTableData"
          />
        </v-col>
        <v-col cols="12" xs="12" md="2" v-if="table_data.length > 0">
          <v-text-field
            v-model="table_search"
            append-icon="mdi-magnify"
            label="Buscar..."
            single-line
            hide-details
            dense
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <v-data-table
            :headers="table_headers"
            :search="table_search"
            :items="table_data"
            :loading="loading"
            dense
          >
            <template v-slot:item.counter="{ item }">
              <span class="font-weight-bold" v-text="item.counter" />
            </template>
            <template v-slot:item.folio_full="{ item }">
              <span :class="!item.rs_service_active ? 'red--text' : ''">
                {{ item.folio_full }}
              </span>
              <span> </span>
            </template>
            <template v-slot:item.rs_service_insured_bill_folio="{ item }">
              {{
                item.rs_service_insured_bill_folio
                  ? item.rs_service_insured_bill_folio
                  : "-"
              }}
            </template>
            <template v-slot:item.beneficiary="{ item }">
              <span v-if="item.rs_type_id != 2">
                ID{{
                  item.doctor_id ? "M" + item.doctor_id : "P" + item.provider_id
                }}
                | {{ item.beneficiary }}
              </span>
              <v-tooltip v-if="item.doctor_id" bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    :color="
                      item.doctor.valid != null
                        ? item.doctor.valid
                          ? 'success'
                          : 'error'
                        : ''
                    "
                  >
                    mdi-{{
                      item.doctor.valid != null
                        ? item.doctor.valid
                          ? "check-"
                          : "alert-"
                        : ""
                    }}decagram
                  </v-icon>
                </template>
                <span
                  v-text="
                    item.doctor.valid_user
                      ? item.doctor.valid_user.email +
                        ' | ' +
                        item.doctor.valid_date
                      : 'Validación pendiente'
                  "
                />
              </v-tooltip>
            </template>
            <template v-slot:item.assured_amount="{ item }">
              {{ numberFormat(item.assured_amount) }}
            </template>
            <template v-slot:item.insurance_pay_amount="{ item }">
              {{
                item.insurance_pay_amount
                  ? numberFormat(item.insurance_pay_amount)
                  : ""
              }}
            </template>
            <template v-slot:item.bank="{ item }">
              <small>
                <b>Banco:</b>
                {{ item.bank ? item.bank.bank : "" }}
                <br />
                <b>CLABE:</b>
                {{ item.clabe }}
                <br />
                <b>Cuenta:</b>
                {{ item.account_number }}
                <br />
                <b>Validación:</b>
                <v-tooltip bottom v-if="item.account_validation_pdf">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      small
                      v-on="on"
                      :href="
                        url_documents +
                        (item.doctor ? '/doctors/' : '/providers/') +
                        item.account_validation_pdf
                      "
                      target="_blank"
                    >
                      <v-icon small>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Ver'" />
                </v-tooltip>
                <i v-else> NO REGISTRADO </i>
              </small>
            </template>
            <template v-slot:item.xmls_amount="{ item }">
              {{ numberFormat(item.xmls_amount) }}
            </template>
            <template v-slot:item.notes_amount="{ item }">
              {{ numberFormat(item.notes_amount) }}
            </template>
            <template v-slot:item.pay_amount="{ item }">
              {{ numberFormat(item.pay_amount) }}
            </template>
            <template v-slot:item.paid_amount="{ item }">
              {{ item.paid_amount ? numberFormat(item.paid_amount) : "" }}
            </template>
            <template v-slot:item.rs_service_active="{ item }">
              <span v-if="!item.rs_service_active" class="red--text">
                SERVICIO ELIMINADO
              </span>
            </template>
            <template v-slot:item.select="{ item }">
              <v-simple-checkbox
                v-if="
                  item.select != null &&
                  item.allow_payment &&
                  (item.rs_service_insured_bill_valid != false ||
                    login.role_id == 1)
                "
                v-model="item.select"
                :ripple="false"
              />
              <v-tooltip
                v-if="
                  !item.rs_service_bill_payment_item &&
                  !item.paid_date &&
                  item.select == null &&
                  item.allow_payment == null
                "
                left
              >
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="orange"> mdi-alert </v-icon>
                </template>
                <span
                  v-text="
                    item.consignment_verify
                      ? (item.rs_type_id != 2 ? 'Médico' : 'Asegurado') +
                        ' en proceso de revisión, sin datos bancarios registrados, servicio sin seguimiento médico o médico no validado'
                      : 'Registro sin remesa asignada'
                  "
                />
              </v-tooltip>
              <v-tooltip
                v-if="
                  !item.rs_service_bill_payment_item &&
                  !item.paid_date &&
                  item.select == null &&
                  item.allow_payment == false
                "
                left
              >
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="red"> mdi-alert </v-icon>
                </template>
                <span v-text="'Datos bancarios no registrados'" />
              </v-tooltip>
              <v-tooltip
                v-if="item.rs_service_insured_bill_valid == false"
                left
              >
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="purple darken-3"> mdi-alert </v-icon>
                </template>
                <span v-text="'Cobro ID no conciliado'" />
              </v-tooltip>
              <span v-if="!item.paid_date && item.rs_service_bill_payment_item">
                EN PROCESO DE PAGO
              </span>
              <span v-if="item.paid_date && !item.complement_folio">
                PAGADO | COMPLEMENTO DE PAGO PENDIENTE
              </span>
              <span v-if="item.paid_date && item.complement_folio">
                PAGO FINALIZADO
              </span>
            </template>
            <template v-slot:item.rs_service_confirm="{ item }">
              <div v-if="item.rs_type.id != 4">
                <div v-if="item.rs_service_confirm_date">
                  <small
                    :class="item.rs_service_confirm ? '' : 'red--text'"
                    v-text="item.rs_service_confirm_date"
                  />
                </div>
              </div>
            </template>
            <template v-slot:item.confirm="{ item }">
              <div v-if="item.rs_type.id != 4">
                <div v-if="item.confirm_date">
                  <small
                    :class="item.confirm ? '' : 'red--text'"
                    v-text="item.confirm_date"
                  />
                  <br />
                  <span
                    v-if="item.confirm_manual"
                    v-text="item.confirm_user.email"
                  />
                  <br />
                  <small v-if="item.confirm_manual"><b>MANUAL</b></small>
                  <br />
                  <v-tooltip v-if="login.role_id == 1" left>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        icon
                        x-small
                        color="error"
                        @click.prevent="confirmationRemove(item)"
                      >
                        <v-icon> mdi-close</v-icon>
                      </v-btn>
                    </template>
                    <span v-text="'Eliminar confirmación'" />
                  </v-tooltip>
                </div>
                <v-tooltip
                  v-if="!item.confirm_date && !item.rs_service_confirm_date"
                  left
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      icon
                      small
                      color="warning"
                      @click.prevent="
                        login.permissions.rs_service_bills_confirmation.read
                          ? confirmationDlg(item)
                          : false
                      "
                    >
                      <v-icon> mdi-alert</v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Pendiente de confirmar'" />
                </v-tooltip>
              </div>
            </template>
            <template v-slot:item.action="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    fab
                    x-small
                    v-on="on"
                    class="ml-1"
                    :color="item.to_stamp ? 'success' : 'warning'"
                    :to="{
                      name: 'facturacion_servicios.detalle',
                      params: { id: item.id },
                    }"
                  >
                    <v-icon> mdi-folder-eye </v-icon>
                  </v-btn>
                </template>
                <span v-text="'Ver documentación'" />
              </v-tooltip>
            </template>
            <template v-slot:item.download_docs="{ item }">
              <v-simple-checkbox
                v-if="item.rs_service_type_id == 3"
                v-model="item.download_docs"
                :ripple="false"
              />
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog
      v-model="payments_dialog"
      scrollable
      persistent
      max-width="1800px"
    >
      <v-card
        tile
        :disabled="payments_dialog_loading"
        :loading="payments_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> Generar orden(es) de pago</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="payments_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="payments_data">
          <v-row>
            <v-col cols="12" />
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'ASEGURADORAS'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Nombre</th>
                              <th>Servicios</th>
                              <th>Cobro aseg.</th>
                              <th>Total a pagar</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(insurance, i) in payments_data.insurances"
                              :key="i"
                            >
                              <td v-text="i + 1" />
                              <td v-text="insurance.name" />
                              <td v-text="insurance.payments.length" />
                              <td
                                v-text="
                                  numberFormat(
                                    insurance.total_insurance_pay_amount
                                  )
                                "
                              />
                              <td
                                v-text="numberFormat(insurance.total_amount)"
                              />
                            </tr>
                            <tr>
                              <td />
                              <td />
                              <td />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(
                                    payments_data.insurance_total_insurance_pay_amount
                                  )
                                "
                              />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(
                                    payments_data.insurance_total_amount
                                  )
                                "
                              />
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'ORDEN(ES) DE PAGO'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-form
                    v-on:submit.prevent
                    ref="form_payments_data"
                    lazy-validation
                  >
                    <v-row>
                      <v-col
                        cols="12"
                        v-for="(
                          payment, i
                        ) in payments_data.rs_service_bill_payments"
                        :key="i"
                      >
                        <v-card class="mx-auto">
                          <v-card-title>
                            <h2
                              class="text-caption"
                              v-text="
                                `${i + 1}) ${payment.provider_brand.name}`
                              "
                            />
                          </v-card-title>
                          <v-divider />
                          <v-card-text>
                            <v-row dense>
                              <v-col cols="12">
                                <v-simple-table dense>
                                  <template v-slot:default>
                                    <thead>
                                      <tr>
                                        <th>#</th>
                                        <th>Folio</th>
                                        <th>Tipo</th>
                                        <th>Aseguradora</th>
                                        <th>Doc. ID</th>
                                        <th>Registro</th>
                                        <th>Recepción</th>
                                        <th>Promesa pago</th>
                                        <th>Banco</th>
                                        <th>Beneficiario</th>
                                        <th v-if="!payment.provider_brand_id">
                                          {{
                                            payment.format == 1
                                              ? "Cuenta"
                                              : "CLABE"
                                          }}
                                        </th>
                                        <th>Cobro aseg.</th>
                                        <th>Total a pagar</th>
                                        <th>Monto a pagar</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        v-for="(
                                          item, j
                                        ) in payment.rs_service_bill_payment_items"
                                        :key="j"
                                      >
                                        <td v-text="j + 1" />
                                        <td v-text="item.folio_full" />
                                        <td v-text="item.rs_type" />
                                        <td v-text="item.insurance.name" />
                                        <td v-text="item.folio" />
                                        <td v-text="item.created_at" />
                                        <td v-text="item.reception_date" />
                                        <td v-text="item.pay_date" />
                                        <td v-text="item.bank" />
                                        <td v-text="item.beneficiary" />
                                        <td v-text="item.beneficiary_account" />
                                        <td
                                          v-text="
                                            numberFormat(
                                              item.insurance_pay_amount
                                            )
                                          "
                                        />
                                        <td
                                          v-text="numberFormat(item.pay_amount)"
                                        />
                                        <td>
                                          <v-text-field
                                            v-if="!payment.generated"
                                            v-model="item.amount"
                                            type="number"
                                            dense
                                            :rules="rules.required"
                                          />
                                          <span
                                            v-else
                                            :class="
                                              parseFloat(item.amount) ==
                                              parseFloat(item.pay_amount)
                                                ? ''
                                                : 'orange--text text--darken-3'
                                            "
                                            v-text="numberFormat(item.amount)"
                                          />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td />
                                        <td />
                                        <td />
                                        <td />
                                        <td />
                                        <td />
                                        <td />
                                        <td />
                                        <td />
                                        <td />
                                        <td
                                          class="font-weight-bold"
                                          v-text="
                                            numberFormat(
                                              payment.total_insurance_pay_amount
                                            )
                                          "
                                        />
                                        <td
                                          class="font-weight-bold"
                                          v-text="
                                            numberFormat(
                                              payment.total_pay_amount
                                            )
                                          "
                                        />
                                        <td
                                          class="font-weight-bold"
                                          v-text="
                                            numberFormat(payment.total_amount)
                                          "
                                        />
                                      </tr>
                                    </tbody>
                                  </template>
                                </v-simple-table>
                              </v-col>
                              <v-col cols="12">
                                <v-btn
                                  v-if="!payment.generated"
                                  block
                                  small
                                  color="primary"
                                  :loading="payment.loading"
                                  @click="paymentGenerated(i)"
                                >
                                  Confirmar
                                  <v-icon right> mdi-download </v-icon>
                                </v-btn>
                                <v-btn
                                  v-else
                                  block
                                  small
                                  color="warning"
                                  @click="paymentRegenerated(i)"
                                >
                                  Editar
                                  <v-icon right> mdi-refresh </v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col cols="12">
                        <v-btn
                          block
                          color="success"
                          :disabled="!payments_data.save"
                          small
                          @click.prevent="paymentsSave"
                        >
                          Generar
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="pending_dlg" scrollable persistent max-width="600px">
      <v-card tile>
        <v-toolbar color="deep-orange darken-2" dark dense>
          <v-toolbar-title> PENDIENTES </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="pending_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="mt-3 text-center">
              <div v-if="bill_comments_pending > 0">
                Fact. | Comentarios aseguradoras ({{ bill_comments_pending }})
              </div>
              <div v-if="doctors_comments_pending > 0">
                Fact. Servicios | Méd. Preguntas o comentarios ({{
                  doctors_comments_pending
                }})
              </div>
              <div>
                <b>
                  ¡Favor de atender est{{
                    bill_comments_pending + doctors_comments_pending > 0
                      ? "os "
                      : "e "
                  }}
                  pendientes lo antes posible!
                </b>
              </div>
              <v-icon color="deep-orange darken-1">mdi-alert</v-icon>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirmation_dlg"
      scrollable
      persistent
      max-width="900px"
    >
      <v-card
        tile
        :disabled="confirmation_dlg_ldg"
        :loading="confirmation_dlg_ldg"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> CONFIRMACIÓN </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="confirmation_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="confirmation">
          <v-row>
            <v-col cols="12" class="mt-3" />
            <v-col cols="12">
              <v-row dense>
                <v-col v-if="rha" cols="12">
                  <RhaWithRsService :rha="rha" />
                </v-col>
                <v-col cols="12" xs="6" md="8">
                  URL:
                  <small id="link" v-text="confirmation.link" />
                </v-col>
                <v-col cols="12" xs="6" md="4" class="text-right">
                  <v-btn text color="success" @click="copy()">
                    Copiar URL</v-btn
                  >
                  <v-btn
                    v-if="
                      login.permissions.rs_service_bills_confirmation.update
                    "
                    text
                    color="warning"
                    @click="confirmationManual()"
                  >
                    Manual
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgAlert,
  msgConfirm,
  rules,
  URL_DOCUMENTS,
  dateTimeToFile,
  base64ToArrayBuffer,
} from "../../control";
import FaqDlg from "../../components/FaqDlg.vue";
import RhaWithRsService from "../../components/RhaWithRsService.vue";

export default {
  components: {
    FaqDlg,
    RhaWithRsService,
  },
  data() {
    return {
      url_documents: URL_DOCUMENTS,
      login: this.$store.getters.getLogin,
      loading: false,
      table_data: [],
      table_search: "",
      table_headers: [
        { text: "#", filterable: true, value: "counter" },
        { text: "Folio", filterable: true, value: "folio_full" },
        { text: "Tipo", filterable: true, value: "rs_type" },
        {
          text: "Generación carta",
          filterable: true,
          value: "letter_generated",
        },
        { text: "Aseguradora", filterable: true, value: "insurance.name" },
        { text: "ID SM", filterable: true, value: "enrollment" },
        { text: "Asegurado", filterable: true, value: "insured_full_name" },
        { text: "Médico | Proveedor", filterable: true, value: "beneficiary" },
        { text: "Banco", filterable: true, value: "bank" },
        {
          text: "Consult. | Hosp.",
          filterable: true,
          value: "doctor.provider_trade_name",
        },
        {
          text: "Cobro ID",
          filterable: true,
          value: "rs_service_insured_bill_folio",
        },
        {
          text: "Núm. aprobación",
          filterable: true,
          value: "rs_service_insured_bill_approval_number",
        },
        {
          text: "Tarjeta",
          filterable: true,
          value: "rs_service_insured_bill_card",
        },
        {
          text: "Fact. cobro",
          filterable: true,
          value: "rs_service_insured_bill_stamp_folio",
        },
        {
          text: "Monto Asegurado",
          filterable: true,
          value: "assured_amount",
        },
        { text: "Doc. ID", filterable: true, value: "folio" },
        {
          text: "Registro",
          filterable: true,
          value: "created_at",
        },
        {
          text: "Doc. creada por",
          filterable: true,
          value: "created_by_email",
        },
        {
          text: "Folio Factura",
          filterable: true,
          value: "rs_service_bill_document.xml_folio",
        },
        {
          text: "Recepción",
          filterable: true,
          value: "reception_date",
        },
        {
          text: "Total Factura",
          filterable: true,
          value: "xmls_amount",
        },
        {
          text: "N. crédito",
          filterable: true,
          value: "notes_amount",
        },
        {
          text: "Total a pagar",
          filterable: true,
          value: "pay_amount",
        },
        {
          text: "Promesa pago",
          filterable: true,
          value: "pay_date",
        },

        {
          text: "Monto pagado",
          filterable: true,
          value: "paid_amount",
        },
        {
          text: "Fecha de Pago",
          filterable: true,
          value: "paid_date",
        },
        {
          text: "Folio complemento",
          filterable: true,
          value: "complement_folio",
        },
        {
          text: "Cobro aseg.",
          filterable: true,
          value: "insurance_pay_amount",
        },
        {
          text: "A. registro",
          filterable: true,
          value: "insurance_reception_date_time",
        },
        {
          text: "A. promesa pago",
          filterable: true,
          value: "insurance_pay_date",
        },
        {
          text: "A. núm. trámite",
          filterable: true,
          value: "insurance_procedure",
        },
        {
          text: "Contratante",
          filterable: true,
          value: "insured.contractor.name",
        },
        {
          text: "Observación",
          filterable: true,
          value: "observation",
        },
        {
          text: "Respuesta",
          filterable: true,
          value: "observation_response",
        },
        {
          text: "Obs. admin.",
          filterable: true,
          value: "observation_admin",
        },
        {
          text: "Restitución",
          filterable: true,
          value: "insurance_restitution",
        },
        {
          text: "Contacto aseg.",
          filterable: true,
          value: "contact",
        },
        {
          text: "Confirm.",
          filterable: true,
          value: "rs_service_confirm",
        },
        {
          text: "Confirm. fact.",
          filterable: true,
          value: "confirm",
        },
        {
          text: "",
          filterable: true,
          value: "rs_service_active",
        },
        { text: "", filterable: true, value: "select" },
        { text: "", value: "action", sortable: false },
        { text: "Descargar", filterable: true, value: "download_docs" },
      ],
      filter: null,
      filters: [
        { id: 1, value: "RECHAZADOS" },
        { id: 2, value: "ASEGURADORA: PENDIENTES DE FACTURAR | VALIDAR" },
        { id: 3, value: "ASEGURADORA: PENDIENTE DE ENVÍO" },
        { id: 4, value: "ASEGURADORA: PAGO PENDIENTE" },
        { id: 6, value: "PAGOS AL DIA" },
        { id: 7, value: "EN PROCESO DE PAGO" },
        { id: 8, value: "CASOS NO TERMINADOS" },
        { id: 13, value: "PENDIENTE COMPLEMENTO DE PAGO" },
        { id: 14, value: "CASOS TERMINADOS" },
      ],
      filter_insurance: [],
      filter_insurances: [],
      filter_insurances_loading: true,
      rs_type_id: null,
      filter_rs_types: [],
      filter_rs_types_loading: true,
      data: null,
      payments_data: null,
      payments_dialog: false,
      payments_dialog_loading: false,
      rules: rules(),
      bill_comments_pending: 0,
      bill_comments_pending_ldg: true,
      doctors_comments_pending: 0,
      doctors_comments_pending_ldg: true,
      pending_dlg: false,
      rs_service_type_id: 1,
      filter_rs_service_types: [],
      filter_rs_service_types_loading: true,
      confirmation: null,
      confirmation_dlg: false,
      confirmation_dlg_ldg: false,
      rha: null,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    getTableData() {
      if (this.filter) {
        this.loading = true;
        this.table_data = [];

        Axios.get(
          URL_API +
            "/rss/services/bills?filter=" +
            this.filter +
            "&filter_insurance=" +
            this.filter_insurance +
            "&rs_type_id=" +
            this.rs_type_id +
            "&rs_service_type_id=" +
            this.rs_service_type_id,
          headersToken(this.login.token)
        ).then((resp) => {
          this.table_data = resp.data.data;
          this.loading = false;
        });
      }
    },
    paymentsDialog() {
      this.payments_data = null;
      this.payments_dialog = true;
      this.payments_dialog_loading = true;
      let counter = 0;
      let payments = {
        rs_service_bill_payments: [],
        save: false,
      };

      for (let item of this.table_data) {
        if (item.select) {
          counter++;

          //CREAR PAGO ITEM
          let payment = {
            id: null,
            amount: item.pay_amount,
            beneficiary_account: null,
            rs_service_bill_id: item.id,
            bank_id: item.bank_id,
            bank: item.bank.bank,
            clabe: item.clabe,
            account_number: item.account_number,
            account_validation_pdf: item.account_validation_pdf,
            beneficiary: item.beneficiary,
            doctor: item.doctor,
            provider: item.provider,
            pay_amount: item.pay_amount,
            insurance_pay_amount: item.insurance_pay_amount,
            insurance_id: item.insurance_id,
            insurance: item.insurance,
            folio_full: item.folio_full,
            folio: item.folio,
            created_at: item.created_at,
            reception_date: item.reception_date,
            pay_date: item.pay_date,
            rs_type: item.rs_type,
          };

          //VERIFICAR SI EXISTE ORDEN DE PAGO
          let brand_exist = false;

          for (let i = 0; i < payments.rs_service_bill_payments.length; i++) {
            const provider_brand_id = payment.bank_id == 1 ? -1 : -2;

            if (
              provider_brand_id ==
              payments.rs_service_bill_payments[i].provider_brand_id
            ) {
              brand_exist = true;

              payment.beneficiary_account =
                payments.rs_service_bill_payments[i].format == 1
                  ? payment.account_number
                  : payment.clabe;

              payments.rs_service_bill_payments[
                i
              ].rs_service_bill_payment_items.push(payment);
              break;
            }
          }

          //CREAR ORDEN DE PAGO SI NO EXISTE
          if (!brand_exist) {
            payments.rs_service_bill_payments.push({
              id: null,
              format: payment.bank_id == 1 ? 1 : 2,
              provider_brand_id: payment.bank_id == 1 ? -1 : -2,
              provider_brand: {
                name: `SIN CONCENTRADOR (${
                  payment.bank_id == 1 ? "BBVA" : "OTROS"
                })`,
              },
              rs_service_bill_payment_items: [],
              generated: false,
              loading: false,
            });

            const i = payments.rs_service_bill_payments.length - 1;

            payment.beneficiary_account =
              payments.rs_service_bill_payments[i].format == 1
                ? payment.account_number
                : payment.clabe;

            payments.rs_service_bill_payments[
              i
            ].rs_service_bill_payment_items.push(payment);
          }
        }
      }

      if (counter > 0) {
        payments = this.paymentsTotals(payments);

        //ORDENAR
        payments.rs_service_bill_payments =
          payments.rs_service_bill_payments.sort(
            (a, b) => b.provider_brand_id - a.provider_brand_id
          );

        //AJUSTAR PROVIDER_BRAND_ID A 0
        for (let i = 0; i < payments.rs_service_bill_payments.length; i++) {
          if (payments.rs_service_bill_payments[i].provider_brand_id < 0) {
            payments.rs_service_bill_payments[i].provider_brand_id = null;
          }
        }

        this.payments_data = payments;
        this.payments_dialog_loading = false;
      } else {
        this.payments_dialog = false;
        this.payments_dialog_loading = false;
        this.$swal.fire(
          msgAlert(
            "warning",
            "No existe ningun elemento seleccionado para poder ejecutar esta acción"
          )
        );
      }
    },
    paymentsTotals(payments) {
      this.payments_dialog_loading = true;

      payments.insurances = [];
      payments.insurance_total_amount = 0;
      payments.insurance_total_insurance_pay_amount = 0;

      for (let rs_service_bill_payment of payments.rs_service_bill_payments) {
        rs_service_bill_payment.total_amount = 0;
        rs_service_bill_payment.total_pay_amount = 0;
        rs_service_bill_payment.total_insurance_pay_amount = 0;

        for (const payment of rs_service_bill_payment.rs_service_bill_payment_items) {
          rs_service_bill_payment.total_amount += parseFloat(payment.amount);
          rs_service_bill_payment.total_pay_amount += parseFloat(
            payment.pay_amount
          );
          rs_service_bill_payment.total_insurance_pay_amount += parseFloat(
            payment.insurance_pay_amount
          );

          let insurance_exist = false;

          for (let j = 0; j < payments.insurances.length; j++) {
            if (payments.insurances[j].id == payment.insurance_id) {
              insurance_exist = true;

              payments.insurances[j].total_amount += parseFloat(payment.amount);
              payments.insurances[j].total_insurance_pay_amount += parseFloat(
                payment.insurance_pay_amount
              );

              payments.insurance_total_amount += parseFloat(payment.amount);
              payments.insurance_total_insurance_pay_amount += parseFloat(
                payment.insurance_pay_amount
              );

              payments.insurances[j].payments.push(payment);
              break;
            }
          }

          if (!insurance_exist) {
            payments.insurances.push({
              id: payment.insurance_id,
              name: payment.insurance.name,
              total_amount: parseFloat(payment.amount),
              total_insurance_pay_amount: parseFloat(
                payment.insurance_pay_amount
              ),
              payments: [],
            });

            payments.insurance_total_amount += parseFloat(payment.amount);
            payments.insurance_total_insurance_pay_amount += parseFloat(
              payment.insurance_pay_amount
            );

            const i = payments.insurances.length - 1;
            payments.insurances[i].payments.push(payment);
          }
        }
      }

      return payments;
    },
    paymentGenerated(i) {
      this.payments_data.rs_service_bill_payments[i].loading = true;
      this.payments_data = this.paymentsTotals(this.payments_data);

      this.payments_dialog_loading = false;

      this.payments_data.rs_service_bill_payments[i].generated = true;
      this.payments_data.save = true;

      for (const payment of this.payments_data.rs_service_bill_payments) {
        if (!payment.generated) {
          this.payments_data.save = false;
          break;
        }
      }
    },
    paymentRegenerated(i) {
      this.payments_data.rs_service_bill_payments[i].generated = false;
      this.payments_data.rs_service_bill_payments[i].loading = false;
      this.payments_data.save = false;
    },
    paymentsSave() {
      if (this.$refs.form_payments_data.validate()) {
        this.$swal
          .fire(msgConfirm(`¿Confirma generar las ordenes de pago?`))
          .then((response) => {
            if (response.isConfirmed) {
              this.payments_dialog_loading = true;

              Axios.post(
                `${URL_API}/rss/services/bills/payments`,
                this.payments_data,
                headersToken(this.login.token)
              ).then((response) => {
                this.$swal.fire(
                  msgAlert(
                    response.data.success ? "success" : "error",
                    response.data.message
                  )
                );

                if (response.data.success) {
                  this.payments_dialog = false;
                  this.getTableData();
                } else {
                  console.log(response.data.message);
                }

                this.payments_dialog_loading = false;
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    numberFormat(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    createXlsx() {
      if (this.filter) {
        this.loading = true;

        Axios.get(
          URL_API +
            "/rss/services/bills/filter/report?filter=" +
            this.filter +
            "&filter_insurance=" +
            this.filter_insurance +
            "&rs_type_id=" +
            this.rs_type_id +
            "&rs_service_type_id=" +
            this.rs_service_type_id,
          headersToken(this.login.token)
        ).then((res) => {
          if (res.data.success) {
            this.fileDownload(res.data.data, "xlsx", "fact_servicios");
            this.loading = false;
          } else {
            this.loading = false;
            this.$swal.fire(msgAlert("error", "Error al generar archixo XLSX"));
          }
        });
      }
    },
    fileDownload(base64, ext, name) {
      const blob = new Blob([base64ToArrayBuffer(base64)], {
        type: "application/" + ext,
      });
      let downloadLink = document.createElement("a");
      downloadLink.setAttribute("target", "_blank");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = "SM_" + name + "_" + dateTimeToFile() + "." + ext;
      downloadLink.click();
    },
    downloadZip() {
      let rss = [];

      for (let item of this.table_data) {
        if (item.download_docs) {
          rss.push({
            rs_id: item.rs_id,
          });
        }
      }

      if (rss.length > 0) {
        this.loading = true;

        Axios.post(
          URL_API + "/rs/service/bills/zip",
          {
            rss: rss,
          },
          headersToken(this.login.token)
        ).then((response) => {
          this.$swal.fire(
            msgAlert(
              response.data.success ? "success" : "error",
              response.data.message
            )
          );
          if (response.data.success) {
            window.open(
              this.url_documents + "/zips/" + response.data.data,
              "_blank"
            );
          }

          this.loading = false;
        });
      } else {
        this.$swal.fire(
          msgAlert("warning", "Selecciona al menos un caso para descargar")
        );
      }
    },
    copy() {
      var codigoACopiar = document.getElementById("link");
      var seleccion = document.createRange();
      seleccion.selectNodeContents(codigoACopiar);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(seleccion);
      var res = document.execCommand("copy");
      window.getSelection().removeRange(seleccion);

      this.$swal.fire(msgAlert("success", "URL copiado en portapapeles"));
    },
    confirmationDlg(item) {
      this.confirmation = {
        link:
          window.location.origin + "/confirmacion_servicio/" + btoa(item.id),
        rs_service_bill_id: item.id,
        counter: item.counter,
      };
      this.confirmation_dlg_ldg = true;
      this.confirmation_dlg = true;

      Axios.get(
        URL_API + "/rss/services/" + item.rs_service_id + "/rha",
        headersToken(this.login.token)
      ).then((res) => {
        this.rha = res.data.data;
        this.confirmation_dlg_ldg = false;
      });
    },
    confirmationManual() {
      this.$swal
        .fire(msgConfirm(`¿Realizar confirmación manual del servicio?`))
        .then((resp) => {
          if (resp.isConfirmed) {
            this.confirmation_dlg_ldg = true;

            Axios.post(
              `${URL_API}/rss/services/bills/confirmation`,
              {
                rs_service_bill_id: this.confirmation.rs_service_bill_id,
                confirm: true,
                confirm_manual: true,
              },
              headersToken(this.login.token)
            ).then((resp) => {
              this.$swal.fire(
                msgAlert(
                  resp.data.success ? "success" : "error",
                  resp.data.message
                )
              );

              if (resp.data.success) {
                this.table_data[this.confirmation.counter - 1].confirm =
                  resp.data.data.confirm;
                this.table_data[this.confirmation.counter - 1].confirm_date =
                  resp.data.data.confirm_date;
                this.table_data[this.confirmation.counter - 1].confirm_manual =
                  resp.data.data.confirm_manual;
                this.table_data[this.confirmation.counter - 1].confirm_user_id =
                  resp.data.data.confirm_user_id;
                this.table_data[this.confirmation.counter - 1].confirm_user =
                  resp.data.data.confirm_user;

                this.confirmation_dlg = false;
              } else {
                console.log(resp.data.message);
              }

              this.confirmation_dlg_ldg = false;
            });
          }
        });
    },
    confirmationRemove(item) {
      this.$swal
        .fire(msgConfirm(`¿Eliminar confirmación del servicio?`))
        .then((resp) => {
          if (resp.isConfirmed) {
            this.loading = true;

            this.confirmation = {
              rs_service_bill_id: item.id,
              counter: item.counter,
            };

            Axios.post(
              `${URL_API}/rss/services/bills/confirmation_remove`,
              {
                rs_service_bill_id: this.confirmation.rs_service_bill_id,
              },
              headersToken(this.login.token)
            ).then((resp) => {
              this.$swal.fire(
                msgAlert(
                  resp.data.success ? "success" : "error",
                  resp.data.message
                )
              );

              if (resp.data.success) {
                this.table_data[this.confirmation.counter - 1].confirm = false;
                this.table_data[this.confirmation.counter - 1].confirm_date =
                  null;
                this.table_data[
                  this.confirmation.counter - 1
                ].confirm_manual = false;
                this.table_data[this.confirmation.counter - 1].confirm_user_id =
                  null;
                this.table_data[this.confirmation.counter - 1].confirm_user =
                  null;

                this.confirmation_dlg = false;
              } else {
                console.log(resp.data.message);
              }

              this.loading = false;
            });
          }
        });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    let type_bill = this.$route.name;
    type_bill = type_bill.split(".");
    type_bill = type_bill[0];
    type_bill = type_bill == "facturacion" ? 1 : 2;

    Axios.get(
      URL_API + "/bill_comments/total_pending?type_bill=" + type_bill,
      headersToken(this.login.token)
    ).then((res) => {
      this.bill_comments_pending = res.data.total_pending;
      this.bill_comments_pending_ldg = false;

      Axios.get(
        URL_API + "/doctors/comments/total_pending",
        headersToken(this.login.token)
      ).then((resp) => {
        this.doctors_comments_pending = resp.data.total_pending;
        this.doctors_comments_pending_ldg = false;

        if (
          this.bill_comments_pending > 0 ||
          this.doctors_comments_pending > 0
        ) {
          this.pending_dlg = true;
        }
      });
    });

    Axios.get(
      `${URL_API}/catalog?name=rs_service_types&no_order=false`,
      headersToken(this.login.token)
    ).then((resp) => {
      this.filter_rs_service_types = resp.data.data;
      this.filter_rs_service_types_loading = false;
    });

    Axios.get(
      `${URL_API}/catalog?name=rs_types&no_order=false`,
      headersToken(this.login.token)
    ).then((resp) => {
      this.filter_rs_types = resp.data.data;

      this.filter_rs_types.push({
        id: null,
        type: "TODOS",
        active: 1,
      });

      this.filter_rs_types_loading = false;
    });

    Axios.get(URL_API + `/insurances`, headersToken(this.login.token)).then(
      (resp) => {
        this.filter_insurances = resp.data.data;
        this.filter_insurances_loading = false;
      }
    );
  },
};
</script>